import React from "react"

import Template from "../components/Roadmap/Template"

const SuggestionBox: React.FC = () => {
  return (
    <Template
      basePath="request-language"
      boardToken="579e4503-4807-3865-4f4f-657124ba1f18"
    />
  )
}

export default SuggestionBox
